import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div>
        <h2 className="text-white text-center font-bold">
          404
          <br/>
          page not found
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
